import request from '@/utils/request'
// 获取布局信息
export const home = ()=>{
   return request({
        method:"POST",
        url: '/api/home/getNavList',
        // params:
    })
}
// 获取轮播
export const swiper = ()=>{
    return request({
         method:"POST",
         url: '/api/home/getRotationList',
         // params:
     })
 }
// 信息资源一级栏目
 export const getContentMenuList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getContentMenuList',
         data
     })
 }
// 热门推荐
 export const getServiceRecList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getServiceRecList',
         data
     })
 }
//  南开咨询
// 
export const getContentRecList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getContentRecList',
         data
     })
 }
//  获取栏目内容
export const getContentByColumnId = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getContentByColumnId',
         data
     })
 }
 //  获取类目内容
export const getServiceContentByColumnId = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getServiceContentByColumnId',
         data
     })
 }

 //  获取文章详情
export const getServiceContentDetail = (id)=>{
    return request({
         method:"GET",
         url: '/api/home/getServiceContentDetail?id='+id,
     })
 }
 
 //  获取服务详情
export const getContentDetail = (id)=>{
    return request({
         method:"GET",
         url: '/api/home/getContentDetail?id='+id,
     })
 }

  //  获取服务菜单
export const getServiceMenuList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getServiceMenuList',
         data
     })
 }

  //  获取子栏目
export const getChildColumn = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getChildColumn',
         data
     })
 }

  //  获取子类目
export const getChildServiceColumn = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getChildServiceColumn',
         data
     })
 }

   //  信息展示
export const getInfoList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getInfoList',
         data
     })
 }

    //  广告
export const getPromList = (data)=>{
    return request({
         method:"POST",
         url: '/api/home/getPromList',
         data
     })
 }
//  /api/home/getTrafficRestriction 获取限行
export const getTrafficRestriction = ()=>{
    return request({
         method:"GET",
         url: '/api/home/getTrafficRestriction',
     })
 }

 export const downloadFile = (data)=>{
     console.log(data)
    return request({
         method:"GET",
         url: `/api/home/downloadFile?fileId=${data.url}&fileName=${data.name}`,
        //  responseType:'blob'
     })
 }


// 子品类
 export const getChildSceneColumn = (data)=>{
    return request({
         method:"POST",
         url: `/api/home/getChildSceneColumn`,
         data
     })
 }

// 场景化推荐

export const getSceneRecList = (data)=>{
    return request({
         method:"POST",
         url: `/api/home/getSceneRecList`,
         data
     })
 }

//获取事项详情
 export const getSceneContentDetail = (id)=>{
    return request({
         method:"GET",
         url: `/api/home/getSceneContentDetail?id=${id}`,
     })
 }
// 获取事项内容 

export const getSceneContentByColumnId = (data)=>{
    return request({
         method:"POST",
         url: `/api/home/getSceneContentByColumnId`,
         data
     })
 }