import axios from 'axios'
import store from '@/store/index.js'
import JSONBig from 'json-bigint'
import router from '../router'
import {sm2} from 'sm-crypto'


export const publicKey =
  '04bdaeee6b1e234271b2a8d6430805e39ba96160c17fbddfb356e6228912bf6938699752d6b69b252b86e0366047574dd9146cb36391988bfbcdc40302213bd6da'
export const privateKey =
  '00b18e690e6fd4ea1185529e20c1fa0d8b425f96218d9c9ecac98ad4d3a0d66376'
// axios.create 方法
const request = axios.create({
  // baseURL: 'http://nankai.tiptimes.com/',
  baseURL: 'https://nkstoreht.tiptimes.com/',
    // transformResponse: [(data) => {
        // return data 
        // ? JSONBig.parse(data) : {}
    // }]
})
//请求拦截器
request.interceptors.request.use((config) => {
    // 往config中的headers添加一个属性
    // if用于判断当前有没有token,比如第一次登录没有token就不需要token了
    // 加密
    config.headers = {
        'Content-Type': 'application/json;charset=UTF-8'
    }
    if (config.data) {
      config.data instanceof Object &&
      (config.data = JSON.stringify(config.data))
      config.data = `04${sm2.doEncrypt(config.data, publicKey)}`
    }
    const paramIndex = config.url.indexOf('?')
    if (paramIndex !== -1) {
      const uri = config.url.substring(paramIndex + 1, config.url.length)
      config.url = config.url.replace(
        uri,
        `encryptedData=04` + sm2.doEncrypt(uri, publicKey)
      )
    }
    return config;
}), (err) => {
    return Promise.reject(err);
}
// 响应拦截器 
request.interceptors.response.use(function (res) {
    // 对响应数据做点什么
    // IE 8-9
    if (
      res.data == null &&
      res.config.responseType === 'json' &&
      res.request.responseText != null
    ) {
    } else {
      res.data = JSON.parse(sm2.doDecrypt(res.data, privateKey))
    }
    // console.log(res)
    return res
}, async function (error) {
  console.log(error)
    // 对响应错误做点什么
    if (error.response.status === 401) {
        // 判断是否有token或者refresh_token,当token过去了都需要重发token

    } else {
        return Promise.reject(error);
    }
    console.dir(error);

});

export default request